/* eslint-disable import/prefer-default-export */
/* eslint-disable no-undef */
import axios from 'axios';

const request = ({ endpoint, method = 'GET', ...props }) => {
  const userIdentity = JSON.parse(sessionStorage.getItem('userIdentity'));
  return axios({
    method,
    url: `${process.env.REACT_APP_HOST_URL}${endpoint}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${userIdentity.token}`,
    },
    responseType: 'json',
    ...props,
  }).then((res) => res.data);
};

export const getCampaign = (id) => request({
  endpoint: `Campaign/${id}`,
}); 

export const getCampaigns = (parameters) => request({
  endpoint: `Campaign${parameters}`,
});

export const getMarkets = (id, parameters) => request({
  endpoint: `Campaign/${id}/markets${parameters}`,
});

export const getCampaignsCreate = () => request({
  endpoint: 'Campaign/create',
});

export const addCampaign = (data) => request({
  endpoint: 'Campaign/store',
  method: 'POST',
  data,
});

export const updateCampaign = (data, id) => request({
  endpoint: `Campaign/${id}`,
  method: 'PUT',
  data,
});

export const notifyStations = (data, id) => request({
  endpoint: `Campaign/${id}/notify-stations`,
  method: 'POST',
  data,
});

export const updateMarketDistribution = (data, id) => request({
  endpoint: `Campaign/${id}/market-distribution`,
  method: 'PUT',
  data,
});
export const updateWeeklyDistribution = (data, id) => request({
  endpoint: `Campaign/${id}/weekly-distribution`,
  method: 'PUT',
  data,
});

export const updateBookedCampaign = (id) => request({
  endpoint: `Campaign/${id}/booked`,
  method: 'PUT'
});

export const getMarketRollupHeaders = (campaignId, marketId) => request({
  endpoint: `Campaign/getMarketRollupHeaders?campaignId=${campaignId}&marketId=${marketId}`,
  method: 'GET'
});

export const getCampaignRollupHeaders = (campaignId) => request({
  endpoint: `Campaign/getCampaignRollupHeaders/${campaignId}`,
  method: 'GET'
});

export const checkBookingGenerationStatus = (campaignId, marketId) => request({
  endpoint: `Campaign/${campaignId}/bookings/${marketId}/status`,
  method: 'GET'
});